import React from 'react';

const MapItem = ({name, zoom, icon, type, open, onClick}) => {
  const width = type == "landmark" && zoom < 17 ? 60 : 100 ** (zoom / 7) / 1000;
  return <div className={`map-marker ${open ? 'map-marker--open' : ''}`} onClick={onClick}>
      <div className={`map-icon ${!icon ? "map-icon--pin" : ""}`} onClick={onClick}>
        {type == "landmark" || icon && zoom > 16 ?
          <img src={`/map/${icon}`} alt={name} style={{width:`${width}px`}}/>
        :
          <span className='uk-icon' data-uk-icon="location"></span>
        }
      </div>
      <div className={`map-name ${zoom < 17 ? 'uk-hidden':''}`}>{name}</div>
    </div>
}

export default MapItem
