import React from 'react';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const MapItemInfo = ({body, frontmatter, location, open, onClose }) => {
  return <div className="map-item-info">
      {open ?
        <div className="map-popup map-popup--open">
          <h4><a href={frontmatter.link} target="_blank">{frontmatter.title} <span className='uk-icon' data-uk-icon="sign-out"></span></a></h4>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{body}</ReactMarkdown>
          <a href={`https://www.google.com/maps/dir//${frontmatter.title}/@${location.coordinates[1]},${location.coordinates[0]},15z/`} target="_blank"><span className='uk-icon' data-uk-icon="location"></span> Directions</a>
          <div className="map-item-info__close" onClick={onClose}><span className='uk-icon' data-uk-icon="close"></span></div>
        </div> :
        <div className="map-popup"></div>}
    </div>
}

export default MapItemInfo
