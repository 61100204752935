import React, {useState, useLayoutEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import MapItem from './map-item';
import MapItemInfo from './map-item-info';
import MapCurrentPosition from './map-current';
import { StaticQuery, graphql } from "gatsby"
import Crosshair from '../assets/crosshair.svg'

const query = graphql`
  query LocationQuery {
    allMdx(
      filter: {fields: {source: {eq: "location"}}}
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          id
          fields {
            source
            slug
          }
          frontmatter {
            title
            link
            location
            icon
            order
            type
          }
          body
        }
      }
    }
  }
`

const MapSection = ({}) => {

  const [zoom, setZoom] = useState(15);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(false);
  const [centerCurrent, setCenterCurrent] = useState(false);

  const defaultProps = {
    center: {
      lat: 43.679744,
      lng: -114.364559
    },
    zoom: 15
  };

  const handleApiLoaded = (map, maps) => {
    //do something with map if needed
  };

  function createMapOptions(maps) {
    return {
      googleMapType: maps.MapTypeId.TERRAIN,
      styles: mapStyles,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL
      },
      gestureHandling: "greedy",
      fullscreenControl: false
    };
  }

  useLayoutEffect(()=>{
    if(window?.location.hash){
      setOpen(window?.location.hash.replace("#","/"));
      setZoom(18);
    }
  },[]);

  function handleItemClick(slug){
    setOpen(slug);
    setCenterCurrent(false);
    setZoom(18);
    window?.history.pushState(null, null, `${slug.replace("/","#")}`);
  }

  function handleCurrentClick(){
    if(!current){
      const geolocation = window.navigator.geolocation;
      geolocation.getCurrentPosition(function(position) {
        setCurrent(position);
        setOpen(false);
        setCenterCurrent(true);
        window.setTimeout(()=>{
          setCenterCurrent(false);
        }, 1000);
      },
      function(error) {
        alert(error.message);
      });
      const watchId = geolocation.watchPosition(function(position) {
        setCurrent(position);
      });
    }else{
      setCenterCurrent(true);
      window.setTimeout(()=>{
        setCenterCurrent(false);
      }, 1000);
    }
  }

  let mobile = typeof window != "undefined" && window.matchMedia("screen and (max-width: 768px)")

  return (

    <section className="map-container"  id="map">
      <StaticQuery query={query}
        render={data => {

          const activeLocation = data.allMdx && data.allMdx.edges.find(({node})=>node.fields.slug == open)?.node;
          const activeCenter = activeLocation && JSON.parse(activeLocation.frontmatter.location);

          const mapMarkers = data.allMdx && data.allMdx.edges.map(({node})=>{
            try{
              const location = JSON.parse(node.frontmatter.location);
              return <MapItem onClick={()=>{handleItemClick(node.fields.slug)}} open={node.fields.slug == open} type={node.frontmatter.type || "location"} link={node.frontmatter.link} body={node.body} zoom={zoom} key={node.id} name={node.frontmatter.title} icon={node.frontmatter.icon} lat={location.coordinates[1]} lng={location.coordinates[0]} />
            }catch(e){
              console.log(e);
              return null;
            }
          });

          if(mobile.matches && activeCenter){
            activeCenter.coordinates[1] = activeCenter.coordinates[1] - 0.0007;
          }else if(activeCenter){
            activeCenter.coordinates[0] = activeCenter.coordinates[0] - 0.0005;
          }

          let center = null;
          if(activeCenter){
            center = {
              lat: activeCenter.coordinates[1],
              lng: activeCenter.coordinates[0]
            }
          }else if(current && centerCurrent){
            center = {
              lat: current.coords.latitude,
              lng: current.coords.longitude
            }
          }

          return <>
            <div className="center-current-button" onClick={handleCurrentClick}>
              <Crosshair/>
            </div>
            <MapItemInfo {...activeLocation} location={activeCenter} open={open} onClose={() => setOpen(false)} />
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyCcDjhlbXiJDZrbc0jbQr5SQNPVA9YwoxI" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                options={createMapOptions}
                center={center}
                zoom={zoom}
                onChange={({ zoom, bounds }) => {
                  setZoom(zoom);
                }}
              >
                {mapMarkers}
                {current ? <MapCurrentPosition lat={current.coords.latitude} lng={current.coords.longitude} onClick={handleCurrentClick} /> : null}
              </GoogleMapReact>
            </>
          }} />
      </section>
  )
}


const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ebe3cd"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#523735"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#c9b2a6"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#dcd2be"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ae9e90"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#93817c"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#a5b076"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#447530"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f1e6"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#fdfcf8"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f8c967"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#e9bc62"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e98d58"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#db8555"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#806b63"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8f7d77"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#ebe3cd"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dfd2ae"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#b9d3c2"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#92998d"
      }
    ]
  }
]


export default MapSection;
