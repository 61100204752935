import * as React from "react"

import Layout from "../components/layout"
import Map from "../components/map"

const MapPage = ({data}) => {

    return (
      <Layout page="/map">
        <main className="main">
          <Map />
        </main>
      </Layout>
    )
  }

export default MapPage